<template>
    <div id="category" class="w-full" v-if="props.categories && props.categories.length > 0">
        <div
            class="pb-4 pt-[4px] font-sans text-[20px] font-medium leading-normal tracking-[0.038rem] dark:text-white">
            {{ title }}
        </div>
        <ul
            class="justify-left flex flex-wrap gap-x-[10px] gap-y-[14px] overflow-auto"
            :style="{ maxHeight: expandable ? (expanded ? '6000px' : '128px') : '600px' }">
            <Link
                v-for="(category, index) in categories"
                :key="category.id"
                :href="route('tags.show', category.slug)"
                @click="trackClick(category.name)">
                <li
                    class="rounded-lg border-2 border-solid border-tagborder px-[16px] py-[6px] text-[14px] capitalize leading-[17px] tracking-[0.03rem] hover:text-white focus:outline-none dark:text-white dark:hover:border-mintgreen">
                    {{ category.name }}
                </li>
            </Link>
        </ul>
        <Link
            v-if="expolreAll"
            class="mt-[10px] block rounded py-1 text-left text-[14px] font-bold hover:text-white dark:text-graytext dark:hover:text-white"
            :href="route('tags.index')">
            {{ expandTitle }}
        </Link>
        <button
            v-else-if="categories.length > 9"
            class="mt-[10px] block rounded py-1 text-left text-[14px] font-bold hover:text-white dark:text-graytext dark:hover:text-white"
            @click="toggleExpanded">
            {{ expanded ? 'Less Tags' : expandTitle }}
        </button>
    </div>
</template>

<script setup>
    import { Link } from '@inertiajs/vue3'
    import { ref, computed } from 'vue'
    import posthog from 'posthog-js'

    const props = defineProps({
        categories: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        expandTitle: {
            type: String,
            required: false,
            default: 'Explore All',
        },
        expolreAll: {
            type: Boolean,

            default: false,
        },
        expandable: {
            type: Boolean,
            required: false,
            default: false,
        },
    })

    let expanded = ref(false)

    const toggleExpanded = () => {
        expanded.value = !expanded.value
    }

    const trackClick = label => {
        posthog.capture('Category Clicked', {
            location: 'sidebar',
            label: label,
        })
    }
</script>

<style scoped>
    ul {
        transition: max-height 0.3s ease-in-out;
    }
</style>
